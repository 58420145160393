<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <v.dialog v-model="show" :wait="wait">

    <template #title-left>{{ getTitle() }}</template>


    <v-virtual-scroll
        :items="items"
        :item-height="65"
        height="350px"
        style="opacity: 0.5;"
    >
      <template v-slot:default="{ item }">

        <v-list-item class="pa-0 align-self-auto">
          <v-list-item-action class="mr-3 ">
            <div class="d-flex justify-end">{{ item.create|date }}</div>
            <div style="font-size: small" class="d-flex justify-end">{{ item.create|time }}</div>
          </v-list-item-action>
          <v-list-item-content class="text-left pa-0">
            <v-list-item-title style="white-space: normal" v-text="getText(item)"/>
            <!--              <div style="display:inline-block">{{ $lib.text(item.category, params.categories_log) }}</div>-->

            <v-list-item-subtitle>
              {{ item.data.initiator }} обновил данные
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size: small">
              {{ item.data.source }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="item.type==='EXTRACT'">
            <v-btn icon @click="openHTML(item)">
              <v-icon color="grey lighten-1">mdi-eye</v-icon>
            </v-btn>
          </v-list-item-action>

        </v-list-item>

        <v-divider/>

      </template>
    </v-virtual-scroll>

  </v.dialog>
</template>

<script>

let normal_data = {}


export default {
  props: {value: Boolean, flat: Object, params: Object},
  data: function () {
    return {
      items: [],
      wait: true,
    }
  },
  created() {
    normal_data = this.$lib.copy(this.$data)
  },
  computed: {
    show: {
      get() {
        if (this.value) this.form_show()
        return this.value
      },
      set(value) {
        if (!this.value) return // отрабатываем только закрытие формы
        this.$emit('input', value) // чтобы родитель изменил переменную
        // this.form_close()
      }
    }
  },
  watch: {
    // flat_edit: {
    //   deep: true, // глубокое наблюдение за объектом
    //   handler(newValue, oldValue) {
    //     newValue;
    //     oldValue
    //     if (this.flat_edit.floor === '') this.flat_edit.floor = null
    //     if (this.flat_edit.entrances === '') this.flat_edit.entrances = null
    //     this.btnOK_disabled = !this.$lib.different(this.flat, this.flat_edit)
    //   }
    // }
  },
  methods: {
    get_img() {
      return require('@/assets/avatar2.svg')
      // if (this.product.type === 'PRD') {
      //   return require('@/views/shop/assets/5.svg')
      // } else {
      //   return require('@/views/shop/assets/clock.svg')
      // }
    },
    getTitle() {
      let type = this.flat.apartment ? 'Помещение № ' : 'Квартира № '
      return type + ' ' + this.flat.number
    },
    form_show() {
      // console.log('params', this.params);
      this.$lib.restore(this.$data, normal_data)
      this.$axios.get(this.$url.server_project_flat_log.replace('55555', this.flat.id), {zLoader: [this, 'wait']})
          .then(response => {
            this.items = response.data
          })
    },
    getText(item) {
      let txt = ''
      item.data.actions.map(action => {
        txt = txt + this.$lib.text(action, this.params.actions) + ', '
      })
      return txt.slice(0, -2)
    },
    openHTML(item) {
      // console.log('data', this.data);
      window.open(`${this.$store.state.domen}/server/api/v1/project/extractToHTML?cadastr=${this.flat.cadNumber}&name=${item.name}&token=${this.$store.state.token}`,
          '_blank');
    },

    // form_close() {
    //
    // },
  },
  components: {
    "v.dialog": () => import('@/site/componets/dialog'),
  }
}

const items =
    [
      {
        "create": "2022-11-29T17:54:23.666876Z",
        "type": "EXTRACT",
        "name": "extract-2.json",
        "data": {
          "text": "обновленна структура данных",
          "source": "Response-80-269969793(ошибка).zip",
          "initiator": "superuser"
        }
      },
      {
        "create": "2022-11-28T15:55:43.475522Z",
        "type": "EXTRACT",
        "name": "extract-1.json",
        "data": {
          "text": "обновленна структура данных"
        }
      },
      {
        "create": "2022-11-28T15:55:43.475522Z",
        "type": "MANUAL",
        "name": "ext-1.json",
        "data": {
          "text": "xxx xxxxxxx xxxxx xxxx"
        }
      }
    ]

items
// const items = [
//   {
//     "create": "2022-07-09T18:06:08.225140Z",
//     "category": "FLAT.UPDATE.ROSREESTR",
//     "initiator": "cron",
//     "source": "rosreestr.2.json",
//     "text": "k1186"
//   },
//   {
//     "create": "2022-07-09T17:41:12.588910Z",
//     "category": "FLAT.UPDATE.ROSREESTR",
//     "initiator": "cron",
//     "source": "rosreestr.1.json",
//     "text": "k1186"
//   }
// ]

</script>
